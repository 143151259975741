import React from 'react';

import './App.css';
import {Redirect, Route, Switch, withRouter} from 'react-router'

import Get from "./common/Get";


const app = function App() {
	return (
		<Switch>
			<Route path='/1'>
				<Get param={"myrealfood_app"}/>
			</Route>
			<Route path='/2'>
				<Get param={"carlosriosq"}/>
			</Route>
			<Route path='/3'>
				<Get param={"realfooding"}/>
			</Route>
			<Route path='/'>
				<Get/>
			</Route>
			<Route path='*'>
				<Redirect to='/'/>
			</Route>
		</Switch>
	);
}

export default withRouter(app);
