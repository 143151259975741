import React from 'react'

export default function Get(props) {

	const getMobileOperatingSystem = () => {
		let userAgent = navigator.userAgent || navigator.vendor || window.opera;

		// Windows Phone must come first because its UA also contains "Android"
		if (/windows phone/i.test(userAgent)) {
			return "Windows Phone";
		}

		if (/android/i.test(userAgent)) {
			return "Android";
		}

		// iOS detection from: http://stackoverflow.com/a/9039885/177710
		if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
			return "iOS";
		}

		return "unknown";
	};

	let param = '';
	if (props.param) {
		param = `?id=${props.param}`;
	} else {
		if(!!new URLSearchParams(window.location.search).get('id')) {
			param = `?id=${new URLSearchParams(window.location.search).get('id')}`;
		}
	}

	let otherURL = `https://myrealfood.app/get/${param}`;
	let iOSURL = `https://myrealfood.app/get/${param}`;
	let androidURL = `https://myrealfood.app/get/${param}`;

	return (
		<div style={{marginTop: '1em'}}>
			{getMobileOperatingSystem() === 'Android' && window.location.assign(androidURL)}
			{getMobileOperatingSystem() === 'iOS' && window.location.assign(iOSURL)}
			{getMobileOperatingSystem() === 'unknown' && window.location.assign(otherURL)}
		</div>
	)
}
